export const posRoutes = [
  {
    path: "/pos/login",
    name: "FrontOfficeLogin",
    component: () =>
      import(/* webpackChunkName: "fontChuck" */ "@/pos/views/LoginPage.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/pos/home",
    name: "FrontOfficeHome",
    component: () =>
      import(/* webpackChunkName: "fontChuck" */ "@/pos/views/IndexView.vue"),
    meta: {
      title: "Front Office Home",
    },
  },
  {
    path: "/pos/orders",
    name: "WaiterOrders",
    component: () =>
      import(
        /* webpackChunkName: "fontChuck" */ "@/pos/views/WaiterOrders.vue"
      ),
    meta: {
      title: "Waiter Orders",
      requireFrontOfficeAuth: true,
    },
  },
  {
    path: "/pos/orders/:action/:reference?",
    name: "OrdersCreator",
    component: () =>
      import(
        /* webpackChunkName: "fontChuck" */ "@/pos/views/OrderCreator.vue"
      ),
    meta: {
      title: "Order Creator",
      requireFrontOfficeAuth: true,
    },
  },
  {
    path: "/pos/kitchen/orders",
    name: "KitchenOrders",
    component: () =>
      import(
        /* webpackChunkName: "fontChuck" */ "@/pos/views/DestinationOrders.vue"
      ),
    meta: {
      title: "Kitchen Orders",
      requireFrontOfficeAuth: true,
    },
  },
  {
    path: "/pos/bar/orders",
    name: "BarOrders",
    component: () =>
      import(
        /* webpackChunkName: "fontChuck" */ "@/pos/views/DestinationOrders.vue"
      ),
    meta: {
      title: "Bar Orders",
      requireFrontOfficeAuth: true,
    },
  },
  {
    path: "/pos/reports/sales",
    name: "FrontOfficeSalesReport",
    component: () =>
      import(
        /* webpackChunkName: "fontChuck" */ "@/index/views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Sales Report",
      requireFrontOfficeAuth: true,
    },
  },
];
