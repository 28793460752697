import { createStore } from "vuex";

export default createStore({
  state: {
    user: {},
    isLoading: false,
    loadingTask: null,
    requestPercentage: 0,
    flashMessage: null,
    selectedCompany: JSON.parse(localStorage.getItem("_company")) || null,
    companies: [],
    routes: {},
    settings: {
      site_address: "Rubavu, Rwanda",
      site_name: "BALCON",
      currency: "RWF",
      contact_one: "0788351167",
      app_phone: "+250788900636",
      app_email: "billsbell@gmail.com",
      app_tin: "106548506",
      site_logo: "/img/logo.png",
    },
  },
  getters: {
    user: (state) => state.user || {},
  },
  mutations: {
    SET_PAYMENT_META(state, data) {
      state.accounts = data.accounts;
      state.paymentModes = data.modes;
    },
    SET_CURRENT_USER(state, user) {
      state.user = user;
    },
    SET_AJAX_TASK(state, task) {
      state.loadingTask = task;
    },
    SET_FLASH_MESSAGE(state, message) {
      state.flashMessage = message;
    },
    REQUEST_RESOLVED(state) {
      state.isLoading = false;
      state.loadingTask = null;
      if (state.hasActiveModal) {
        state.hasActiveModal = false;
        document.querySelector("#alert-container").remove();
      }
    },
    REGISTER_CONFIRM_MODAL(state) {
      state.loadingTask = "HANDLING_ALERT_ACTION";
      state.hasActiveModal = true;
    },
  },
  actions: {},
  modules: {},
});
